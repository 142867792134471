import React, {Component} from 'react';
import './App.css';
import logo from './images/logo.jpg';
import watch from './images/watch.png';
import order from './images/order.png';
import catalog from './images/catalog.png';
import axios from 'axios';
import escapeRegExp from 'escape-string-regexp';

class App extends Component {
    state = {
        catalog: {},
        query: '',
        result: null,
        activeSearch: false
    };

    componentDidMount() {
        const api = 'https://bridge.buddyweb.fr/api/hunter24/prices';
        const self = this;
        axios.get(api).then(function(response) {
            self.setState({
                catalog: response.data,
            });
        });
    }

    handleKeyPress = (event) => {
      if (event.key === 'Enter'){
          this.search();
      }
    };

    updateQuery = (event) => {
        this.setState({ query: event.target.value });
    };

    search = () => {
        const query = this.state.query;
        if (!query) {
            this.setState({
                activeSearch: false
            });
            return null;
        }

        this.setState({
            activeSearch: true
        });

        if (query.length === 5) {
            const match = new RegExp(escapeRegExp(this.state.query.trim()), 'i');
            const result = this.state.catalog.filter((item) => match.test(item.code));
            this.setState({
                result: result
            });
        }
    };

    renderResult = () => {
        const query = this.state.query;

        if(!this.state.result || this.state.result.length < 1 || query.length > 5 || query.length > 5) {
            return (
                <section className={'result'}>
                    <div className={'searchSubTitle'}>
                        Atsiprašome, tačiau tokio produkto neradome. Patikrinkite ar teisingai nurodėte prekės kodą.
                    </div>
                </section>
            );
        }

        const [ firstResult ] = this.state.result;
        return (
            <section className={'result'}>
                <div className={'product'}>
                    <div className={'name'}>
                        Aprašymas
                    </div>
                    <div className={'description'}>
                        {firstResult.description}
                    </div>
                </div>
                <div className={'product'}>
                    <div className={'name'}>
                        Prekės kodas
                    </div>
                    <div className={'description'}>
                        {firstResult.code}
                    </div>
                </div>
                <div className={'product'}>
                    <div className={'name'}>
                        Parduotuvės kaina

                    </div>
                    <div className={'description'}>
                        {firstResult.price}
                    </div>
                </div>
                <div className={'product'}>
                    <div className={'name'}>
                        Išankstinio užsakymo kaina
                    </div>
                    <div className={'description'}>
                        {((parseFloat(firstResult.price)) * 0.9).toFixed(2)}<span> €</span>
                    </div>
                </div>
            </section>
        )
    };

    render() {
        return (
            <div className="App">
                <div className={'overlay'}></div>
            <div>
                <section className={'header'}>
                    <img src={logo} alt={'Hunter'}/>
                    <h1 className={'title'}>IŠANKSTINIAI UŽSAKYMAI</h1>
                    <p className={'subtitle'}>Užsakymai priimami el. p. info@hunter24.lt</p>
                </section>
                <section className={'search'}>
                    <input type="text" onKeyPress={this.handleKeyPress} onChange={(event) => this.updateQuery(event)} className="form-control" id="kodas" name="kodas" placeholder="Įveskite 5 skaitmenų prekės kodą" autoFocus="" required=""/>
                    <button type="submit" className="btn btn-theme" onClick={this.search}>Ieškoti kainos!</button>
                </section>

                {this.state.activeSearch && this.renderResult()}

                <section className={'howToOrder'}>
                    <div className={'title'}>
                        Kaip užsakyti?
                    </div>
                    <ul>
                        <li>
                            <img src={catalog} alt={'Hunter'}/>
                            <p>1. Peržiūrėkite prekes internetiniame kataloge.</p>
                        </li>
                        <li>
                            <img src={watch} alt={'Hunter'}/>
                            <p>2. Išsirinkite prekes. Kainas galite pasitikrinti paieškos laukelyje.</p>
                        </li>
                        <li>
                            <img src={order} alt={'Hunter'}/>
                            <p>3. Atsiųskite užsakymą el. paštu info@hunter24.lt</p>
                        </li>
                    </ul>

                </section>
                <section className={'viewCatalog'}>
                    <h2 className={'title'}>Peržiūrėkite katalogą internete</h2>
                    <iframe width="80%" height="400px" src="https://www.yumpu.com/en/embed/view/KnSEIOTdBQGAxuc7" title="Hunter katalogas" frameBorder="0"
                            allowFullScreen
                            allowTransparency>
                    </iframe>
                </section>

                <section className={'footer'}>
                    © Visos teisės saugomos.
                </section>
            </div>
            </div>
        );
    }
}

export default App;
